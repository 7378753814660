import { parse } from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  fetchPredictValue,
  buildQueryString,
} from "../../../../../api/predict";
import { simulationParamMapping } from "../../../../../utils/simulationParamMapping";
import {
  useSimulatedCostDispatch,
  useSimulatedCostState,
} from "../../../providers/SimulationProvider/hooks";
import { useLoadingState } from "../../../providers/SimulationProvider/hooks/useLoadingState";
import { useRemovalItemCostsState } from "../../../providers/SimulationProvider/hooks/useRemovalItemCostsState";
import { RemovalItemCosts } from "../../SimulationForm/SimulationFormValues";

type HookReturnType = {
  loading: boolean | undefined;
  simulatedCost: number | undefined;
  removalItemCostsState: RemovalItemCosts | undefined;
};

export const useSimulationResults = (): HookReturnType => {
  const search = useLocation().search;
  const dispatcher = useSimulatedCostDispatch();

  const queryParams = parse(search);
  const params = simulationParamMapping(queryParams);
  const queryString = buildQueryString(params);

  /* Livからシミュレーターページにランディングした際にAPIを叩く */
  useEffect(() => {
    (async () => {
      const response = await fetchPredictValue(queryString);
      if (response.value) dispatcher(response.value.predictedValue);
    })();
  }, []);

  const loading = useLoadingState();
  const simulatedCost = useSimulatedCostState();
  const removalItemCostsState = useRemovalItemCostsState();

  return {
    loading,
    simulatedCost,
    removalItemCostsState,
  };
};
