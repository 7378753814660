import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { STONES } from "../../../../../../../common/constants/stone";
import { useStoneField } from "./hooks/useStoneField";

export const StoneField = (): JSX.Element => {
  const { field, loading, stoneValue, handleChange } = useStoneField();

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl fullWidth>
      <InputLabel>庭石</InputLabel>
      <Select
        {...field}
        value={stoneValue}
        onChange={handleChange}
        label="庭石"
      >
        {STONES.map((type, index) => (
          <MenuItem key={index} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
