export type CarportLabel =
  | "未選択"
  | "1台"
  | "2台"
  | "3台"
  | "4台"
  | "5台"
  | "6台"
  | "7台"
  | "8台"
  | "9台"
  | "10台";
export type CarportValue =
  | ""
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "9"
  | "10";

export const CARPORTS: {
  label: CarportLabel;
  value: CarportValue;
}[] = [
  {
    label: "未選択",
    value: "",
  },
  {
    label: "1台",
    value: "1",
  },
  {
    label: "2台",
    value: "2",
  },
  {
    label: "3台",
    value: "3",
  },
  {
    label: "4台",
    value: "4",
  },
  {
    label: "5台",
    value: "5",
  },
  {
    label: "6台",
    value: "6",
  },
  {
    label: "7台",
    value: "7",
  },
  {
    label: "8台",
    value: "8",
  },
  {
    label: "9台",
    value: "9",
  },
  {
    label: "10台",
    value: "10",
  },
];
