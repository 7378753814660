import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { TreeValue } from "../../../../../../../../common/constants/tree";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type TreeField = Pick<SimulationFormValues, "tree">;

type HookReturnType = {
  field: ControllerRenderProps<TreeField, "tree">;
  loading: boolean | undefined;
  treeValue: TreeValue;
  handleChange: (event: SelectChangeEvent<TreeValue>) => void;
};

export const useTreeField = (): HookReturnType => {
  const { control, watch } = useFormContext<TreeField>();
  const treeField = watch("tree");
  const { field } = useController({
    name: "tree",
    control,
  });

  const [treeValue, setTreeValue] = useState<TreeValue>("");

  const loading = useLoadingState();

  useEffect(() => {
    setTreeValue(treeField ?? "");
  }, [treeField]);

  const handleChange = (event: SelectChangeEvent<TreeValue>): void => {
    const value = event.target.value as TreeValue;
    field.onChange(value);
    setTreeValue(value);
  };

  return {
    field,
    loading,
    treeValue,
    handleChange,
  };
};
