export type BuildingStructureLabel =
  | "木造"
  | "鉄骨造"
  | "コンクリート造"
  | "その他・わからない"
  | "未選択";
export type BuildingStructureValue = "0" | "1" | "2" | "3" | "";

export const BUILDING_STRUCTURES: {
  label: BuildingStructureLabel;
  value: BuildingStructureValue;
}[] = [
  { label: "未選択", value: "" },
  { label: "木造", value: "0" },
  { label: "鉄骨造", value: "1" },
  { label: "コンクリート造", value: "2" },
  { label: "その他・わからない", value: "3" },
];
