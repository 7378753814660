import { useEffect, useState } from "react";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { useLoadingState } from "../../../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../../../SimulationFormValues";

type BlockWallField = Pick<
  SimulationFormValues,
  "block_wall_height" | "block_wall_width"
>;

type HookReturnType = {
  field: ControllerRenderProps<BlockWallField, "block_wall_height">;
  loading: boolean | undefined;
  hasError: boolean;
  heightValue: string | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const useHeightField = (): HookReturnType => {
  const { control, watch } = useFormContext<BlockWallField>();
  const heightField = watch("block_wall_height");
  const widthField = watch("block_wall_width");
  const { field } = useController({
    name: "block_wall_height",
    control,
  });

  const [heightValue, setHeightValue] = useState<string>("");

  const loading = useLoadingState();

  useEffect(() => {
    setHeightValue(heightField ?? "");
  }, [heightField]);

  const hasError = !!(widthField && !heightField);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    field.onChange(value);
    setHeightValue(value);
  };

  return {
    field,
    loading,
    hasError,
    heightValue,
    handleChange,
  };
};
