import { createContext, Dispatch, useState, ReactNode } from "react";
import {
  buildRemovalItemCosts,
  RemovalItemCosts,
} from "../../components/SimulationForm/SimulationFormValues";

type PredictedValue = number;
type PredictedValueDispatch = Dispatch<PredictedValue>;

type Loading = boolean;
type LoadingDispatch = Dispatch<Loading>;

type RemovalItemCostDispatch = Dispatch<RemovalItemCosts>;

// MEMO: React v18からはchildrenを利用する際は明治的に型を定義する必要がある
type Props = {
  children: ReactNode;
};

export const SimulatedCostStateContext = createContext<
  PredictedValue | undefined
>(undefined);

export const SimulatedCostDispatchContext = createContext<
  PredictedValueDispatch | undefined
>(undefined);

export const LoadingStateContext = createContext<Loading | undefined>(
  undefined
);

export const LoadingDispatchContext = createContext<
  LoadingDispatch | undefined
>(undefined);

export const RemovalItemCostsStateContext = createContext<
  RemovalItemCosts | undefined
>(undefined);

export const RemovalItemCostsDispatchContext = createContext<
  RemovalItemCostDispatch | undefined
>(undefined);

export const SimulationProvider = ({ children }: Props) => {
  const [predictedValue, setPredictedValue] = useState(0);
  const [RemovalItemCosts, setRemovalItemCosts] = useState<RemovalItemCosts>(
    buildRemovalItemCosts()
  );
  const [loading, setLoading] = useState(false);

  return (
    <SimulatedCostStateContext.Provider value={predictedValue}>
      <SimulatedCostDispatchContext.Provider value={setPredictedValue}>
        <LoadingStateContext.Provider value={loading}>
          <LoadingDispatchContext.Provider value={setLoading}>
            <RemovalItemCostsStateContext.Provider value={RemovalItemCosts}>
              <RemovalItemCostsDispatchContext.Provider
                value={setRemovalItemCosts}
              >
                {children}
              </RemovalItemCostsDispatchContext.Provider>
            </RemovalItemCostsStateContext.Provider>
          </LoadingDispatchContext.Provider>
        </LoadingStateContext.Provider>
      </SimulatedCostDispatchContext.Provider>
    </SimulatedCostStateContext.Provider>
  );
};
