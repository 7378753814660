import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../SimulationFormValues";
import { BuildingStoryValue } from "../../../../../../../../common/constants/building-story";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";

type BuildingStoryField = Pick<SimulationFormValues, "building_stories">;

type Props = {
  value: BuildingStoryValue;
};

type HookReturnType = {
  field: ControllerRenderProps<BuildingStoryField, "building_stories">;
  hasFieldError: boolean;
  loading: boolean | undefined;
  selectedBuildingStory: BuildingStoryValue;
  handleChange: (event: SelectChangeEvent<BuildingStoryValue>) => void;
};

export const useBuildingStoryField = ({ value }: Props): HookReturnType => {
  const { control } = useFormContext<BuildingStoryField>();
  const { field, fieldState, formState } = useController({
    name: "building_stories",
    control,
  });

  const [selectedBuildingStory, setSelectedBuildingStory] =
    useState<BuildingStoryValue>("");

  const loading = useLoadingState();

  useEffect(() => setSelectedBuildingStory(value), [value]);

  const hasFieldError =
    // フィールドを操作した後に、フィールドが未入力だった場合
    (fieldState.isTouched && selectedBuildingStory === "") ||
    // サブミットボタンが押下された後に、フィールドが未入力だった場合
    (formState.isSubmitted && selectedBuildingStory === "");

  const handleChange = (event: SelectChangeEvent<BuildingStoryValue>): void => {
    const value = event.target.value as BuildingStoryValue;
    field.onChange(value);
    setSelectedBuildingStory(value);
  };

  return {
    field,
    loading,
    hasFieldError,
    selectedBuildingStory,
    handleChange,
  };
};
