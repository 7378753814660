import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import { useConstructionRoadSizeField } from "./hooks/useConstructionRoadSizeField";

type Props = {
  value: "0" | "1" | null;
};

export const ConstructionRoadSizeField = ({ value }: Props): JSX.Element => {
  const {
    field,
    loading,
    hasFieldError,
    selectedConstructionRoadSize,
    handleChange,
  } = useConstructionRoadSizeField({ value });

  if (loading) return <Skeleton variant="text" width={420} height={56} />;

  return (
    <FormControl error={hasFieldError}>
      <FormLabel>前面道路の幅</FormLabel>
      <RadioGroup
        {...field}
        row
        value={selectedConstructionRoadSize}
        onChange={handleChange}
      >
        <FormControlLabel
          value="0"
          control={<Radio />}
          label="4m未満 (すれ違い不可)"
        />
        <FormControlLabel
          value="1"
          control={<Radio />}
          label="4m以上 (すれ違い可能)"
        />
      </RadioGroup>
    </FormControl>
  );
};
