import {
  FormControl,
  FormLabel,
  InputAdornment,
  Skeleton,
  TextField,
} from "@mui/material";
import { useHeightField } from "./hooks/useHeightField";

export const HeightField = (): JSX.Element => {
  const { field, loading, hasError, heightValue, handleChange } =
    useHeightField();

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl>
      <FormLabel>ブロック塀</FormLabel>
      <TextField
        {...field}
        type="number"
        value={heightValue}
        onChange={handleChange}
        error={hasError}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          style: { textAlign: "end" },
        }}
        sx={[{ marginRight: 2 }]}
        InputProps={{
          startAdornment: <InputAdornment position="start">縦</InputAdornment>,
          endAdornment: <InputAdornment position="end">段積</InputAdornment>,
        }}
      />
    </FormControl>
  );
};
