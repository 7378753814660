import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { CarportValue } from "../../../../../../../../common/constants/carport";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type CarportField = Pick<SimulationFormValues, "carport">;

type HookReturnType = {
  field: ControllerRenderProps<CarportField, "carport">;
  loading: boolean | undefined;
  carportValue: CarportValue;
  handleChange: (event: SelectChangeEvent<CarportValue>) => void;
};

export const useCarportField = (): HookReturnType => {
  const { control, watch } = useFormContext<CarportField>();
  const carportField = watch("carport");
  const { field } = useController({
    name: "carport",
    control,
  });

  const [carportValue, setCarportValue] = useState<CarportValue>("");

  const loading = useLoadingState();

  useEffect(() => {
    setCarportValue(carportField ?? "");
  }, [carportField]);

  const handleChange = (event: SelectChangeEvent<CarportValue>): void => {
    const value = event.target.value as CarportValue;
    field.onChange(value);
    setCarportValue(value);
  };

  return {
    field,
    loading,
    carportValue,
    handleChange,
  };
};
