import { ParsedQuery } from "query-string";

/**  Livからのクエリパラメータのマッピング用オブジェクト */
const queryParamsMapper = {
  buildingTypes: {
    dedicated_house: "0",
    apartment: "1",
    condominium: "2",
    building: "3",
    tenement: "4",
    other: "99",
  },
  buildingStories: {
    one: "0",
    two: "1",
    three: "2",
    four_or_more: "3",
  },
  buildingStructures: {
    wooden: "0",
    steel: "1",
    concrete: "2",
    other: "3",
  },
  constructionRoadSizes: {
    less_than_four_meter: "0",
    four_meter_or_more: "1",
  },
  distanceToAdjacentBuildings: {
    less_than_sixty_centimeter: "0",
    sixty_centimeter_or_more: "1",
  },
  roughFloorArea: {
    unknown: "0",
    smaller_or_equal_to_ten: "5",
    between_eleven_and_twenty: "15",
    between_twenty_one_and_thirty: "25",
    between_thirty_one_and_forty: "35",
    between_forty_one_and_fifty: "45",
    between_fifty_one_and_sixty: "55",
    between_sixty_one_and_seventy: "65",
    between_seventy_one_and_eighty: "75",
    between_eighty_one_and_ninety: "85",
    between_ninety_one_and_hundred: "95",
    between_101_and_200: "150",
    between_201_and_300: "250",
    between_301_and_400: "350",
    between_401_and_500: "450",
    between_501_and_600: "550",
    between_601_and_700: "650",
    between_701_and_800: "750",
    between_801_and_900: "850",
    between_901_and_1000: "950",
    over_1000: "1000",
  },
} as const;

/**
 * @description 相場取得のAPIが受け付ける形の値にマッピング
 * @param queryParams Livからのクエリパラメーター
 */
export const simulationParamMapping = (queryParams: ParsedQuery) => {
  const {
    buildingTypes,
    buildingStories,
    buildingStructures,
    constructionRoadSizes,
    distanceToAdjacentBuildings,
    roughFloorArea,
  } = queryParamsMapper;

  const params = {
    building_type: queryParams.building_type
      ? buildingTypes[queryParams.building_type as keyof typeof buildingTypes]
      : null,
    building_stories: queryParams.building_stories
      ? buildingStories[
          queryParams.building_stories as keyof typeof buildingStories
        ]
      : null,
    building_structure: queryParams.building_structure
      ? buildingStructures[
          queryParams.building_structure as keyof typeof buildingStructures
        ]
      : null,
    construction_road_size: queryParams.construction_road_size
      ? constructionRoadSizes[
          queryParams.construction_road_size as keyof typeof constructionRoadSizes
        ]
      : null,
    distance_to_adjacent_building: queryParams.distance_to_adjacent_building
      ? distanceToAdjacentBuildings[
          queryParams.distance_to_adjacent_building as keyof typeof distanceToAdjacentBuildings
        ]
      : null,
    prefecture_id: queryParams.prefecture_id
      ? (queryParams.prefecture_id as string)
      : null,
    floor_area: queryParams.rough_floor_area
      ? roughFloorArea[
          queryParams.rough_floor_area as keyof typeof roughFloorArea
        ]
      : null,
    web_estate_entry_id: queryParams.web_estate_entry_id
      ? (queryParams.web_estate_entry_id as string)
      : null,
    user_id: queryParams.user_id ? (queryParams.user_id as string) : null,
  };

  return params;
};
