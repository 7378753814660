import { FormControl, FormLabel, Skeleton, TextField } from "@mui/material";
import { useFloorAreaFieldField } from "./hooks/useFloorAreaField";

type Props = {
  value: string | null;
};

export const FloorAreaField = ({ value }: Props): JSX.Element => {
  const { field, loading, hasFieldError, floorAreaValue, handleChange } =
    useFloorAreaFieldField({ value });

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl>
      <FormLabel>建物の大きさ(坪)</FormLabel>
      <TextField
        {...field}
        type="number"
        value={floorAreaValue}
        onChange={handleChange}
        error={hasFieldError}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      />
    </FormControl>
  );
};
