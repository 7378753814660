import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { CARPORTS } from "../../../../../../../common/constants/carport";
import { useCarportField } from "./hooks/useCarportField";

export const CarportField = (): JSX.Element => {
  const { field, loading, carportValue, handleChange } = useCarportField();

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl fullWidth>
      <InputLabel>カーポート</InputLabel>
      <Select
        {...field}
        value={carportValue}
        onChange={handleChange}
        label="カーポート"
      >
        {CARPORTS.map((type, index) => (
          <MenuItem key={index} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
