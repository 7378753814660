export type BuildingTypeLabel =
  | "一戸建て住宅"
  | "アパート"
  | "マンション"
  | "ビル"
  | "長屋"
  | "その他（倉庫、蔵、車庫）"
  | "未選択";
export type BuildingTypeValue = "" | "0" | "1" | "2" | "3" | "4" | "99";

export const BUILDING_TYPES: {
  label: BuildingTypeLabel;
  value: BuildingTypeValue;
}[] = [
  {
    label: "未選択",
    value: "",
  },
  {
    label: "一戸建て住宅",
    value: "0",
  },
  {
    label: "アパート",
    value: "1",
  },
  {
    label: "マンション",
    value: "2",
  },
  {
    label: "ビル",
    value: "3",
  },
  {
    label: "長屋",
    value: "4",
  },
  {
    label: "その他（倉庫、蔵、車庫）",
    value: "99",
  },
];
