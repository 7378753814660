import { Button } from "@mui/material";

type Props = {
  disabled: boolean;
};

export const SubmitButton = ({ disabled }: Props): JSX.Element => {
  return (
    <Button type="submit" variant="contained" disabled={disabled}>
      相場を調べる
    </Button>
  );
};
