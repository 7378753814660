import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { BuildingTypeValue } from "../../../../../../../../common/constants/building-type";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type BuildingTypeField = Pick<SimulationFormValues, "building_type">;

type Props = {
  value: BuildingTypeValue;
};

type HookReturnType = {
  field: ControllerRenderProps<BuildingTypeField, "building_type">;
  loading: boolean | undefined;
  hasFieldError: boolean;
  selectedBuildingType: BuildingTypeValue;
  handleChange: (event: SelectChangeEvent<BuildingTypeValue>) => void;
};

export const useBuildingTypeField = ({ value }: Props): HookReturnType => {
  const { control } = useFormContext<BuildingTypeField>();
  const { field, fieldState, formState } = useController({
    name: "building_type",
    control,
  });

  const [selectedBuildingType, setSelectedBuildingType] =
    useState<BuildingTypeValue>("");

  const loading = useLoadingState();

  useEffect(() => setSelectedBuildingType(value), [value]);

  const hasFieldError =
    // フィールドを操作した後に、フィールドが未入力だった場合
    (fieldState.isTouched && selectedBuildingType === "") ||
    // サブミットボタンが押下された後に、フィールドが未入力だった場合
    (formState.isSubmitted && selectedBuildingType === "");

  const handleChange = (event: SelectChangeEvent<BuildingTypeValue>): void => {
    const value = event.target.value as BuildingTypeValue;
    field.onChange(value);
    setSelectedBuildingType(value);
  };

  return {
    field,
    loading,
    hasFieldError,
    selectedBuildingType,
    handleChange,
  };
};
