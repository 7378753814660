import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import {
  BuildingStructureValue,
  BUILDING_STRUCTURES,
} from "../../../../../../../common/constants/building-structure";
import { useBuildingStructureField } from "./hooks/useBuildingStructureField";

type Props = {
  value: BuildingStructureValue;
};

export const BuildingStructureField = ({ value }: Props): JSX.Element => {
  const {
    field,
    loading,
    hasFieldError,
    selectedBuildingStructure,
    handleChange,
  } = useBuildingStructureField({ value });

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl fullWidth>
      <InputLabel>建物の構造</InputLabel>
      <Select
        {...field}
        value={selectedBuildingStructure}
        onChange={handleChange}
        label="建物の構造"
        error={hasFieldError}
      >
        {BUILDING_STRUCTURES.map((type, index) => (
          <MenuItem key={index} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
