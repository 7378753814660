import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { useSimulationResults } from "./hooks/useSimulationResults";

export const SimulationResults = () => {
  const { loading, simulatedCost, removalItemCostsState } =
    useSimulationResults();

  return (
    <React.Fragment>
      {loading ? (
        <Skeleton variant="text" width={600} height={45} />
      ) : (
        <Box>
          <Box>
            <Typography fontSize={30}>
              <strong>
                予想相場 :{" "}
                {simulatedCost
                  ? `${Math.floor(
                      simulatedCost * 0.85
                    ).toLocaleString()} ~ ${Math.floor(
                      simulatedCost * 1.15
                    ).toLocaleString()} 円 程度`
                  : "未算出"}
              </strong>
            </Typography>
          </Box>
          <Box>
            {!!removalItemCostsState?.blockWall && (
              <Box>
                <strong>
                  ブロック塀 :{" "}
                  {Math.floor(removalItemCostsState.blockWall).toLocaleString()}{" "}
                  円 程度
                </strong>
              </Box>
            )}
            {!!removalItemCostsState?.carport && (
              <Box>
                <strong>
                  カーポート :{" "}
                  {Math.floor(removalItemCostsState.carport).toLocaleString()}{" "}
                  円 程度
                </strong>
              </Box>
            )}
            {!!removalItemCostsState?.tree && (
              <Box>
                <strong>
                  樹木 :{" "}
                  {Math.floor(removalItemCostsState.tree).toLocaleString()} 円
                  程度
                </strong>
              </Box>
            )}

            {!!removalItemCostsState?.stone && (
              <Box>
                <strong>
                  庭石 :{" "}
                  {Math.floor(removalItemCostsState.stone).toLocaleString()} 円
                  程度
                </strong>
              </Box>
            )}
            {!!removalItemCostsState?.garbage && (
              <Box>
                <strong>
                  不用品 :{" "}
                  {Math.floor(removalItemCostsState.garbage).toLocaleString()}{" "}
                  円 程度
                </strong>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
