import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { useTreeField } from "./hooks/useTreeField";
import { TREES } from "../../../../../../../common/constants/tree";

export const TreeField = (): JSX.Element => {
  const { field, loading, treeValue, handleChange } = useTreeField();

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl fullWidth>
      <InputLabel>樹木</InputLabel>
      <Select {...field} value={treeValue} onChange={handleChange} label="樹木">
        {TREES.map((type, index) => (
          <MenuItem key={index} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
