import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { BuildingStructureValue } from "../../../../../../../../common/constants/building-structure";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type BuildingStructureField = Pick<SimulationFormValues, "building_structure">;

type Props = {
  value: BuildingStructureValue;
};

type HookReturnType = {
  field: ControllerRenderProps<BuildingStructureField, "building_structure">;
  loading: boolean | undefined;
  hasFieldError: boolean;
  selectedBuildingStructure: BuildingStructureValue;
  handleChange: (event: SelectChangeEvent<BuildingStructureValue>) => void;
};

export const useBuildingStructureField = ({ value }: Props): HookReturnType => {
  const { control } = useFormContext<BuildingStructureField>();
  const { field, fieldState, formState } = useController({
    name: "building_structure",
    control,
  });

  const [selectedBuildingStructure, setSelectedBuildingStructure] =
    useState<BuildingStructureValue>("");

  const loading = useLoadingState();

  useEffect(() => {
    setSelectedBuildingStructure(value);
  }, [value]);

  const hasFieldError =
    // フィールドを操作した後に、フィールドが未入力だった場合
    (fieldState.isTouched && selectedBuildingStructure === "") ||
    // サブミットボタンが押下された後に、フィールドが未入力だった場合
    (formState.isSubmitted && selectedBuildingStructure === "");

  const handleChange = (
    event: SelectChangeEvent<BuildingStructureValue>
  ): void => {
    const value = event.target.value as BuildingStructureValue;
    field.onChange(value);
    setSelectedBuildingStructure(value);
  };

  return {
    field,
    loading,
    hasFieldError,
    selectedBuildingStructure,
    handleChange,
  };
};
