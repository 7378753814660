import { Box, Typography } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { SubmitButton } from "./components/SubmitButton";
import { useSimulationForm } from "./hooks/useSimulationForm";
import { BuildingFields } from "./components/BuildingFields/BuildingFields";
import { RemovalItemsFields } from "./components/RemovalItemsFields";
import { RemovalItemsFieldsVisible } from "./components/RemovalItemsFieldsVisible";
import { ErrorAlert } from "../../../../components/ErrorAlert";
import { FieldWrapper } from "./components/FieldWrapper";

export const SimulationForm = () => {
  const {
    formMethods,
    loading,
    errorText,
    removalItemsFieldsVisible,
    handleRemovalItemsFieldsVisibleChange,
    handleSubmit,
  } = useSimulationForm();

  return (
    <form onSubmit={handleSubmit}>
      <FormProvider {...formMethods}>
        <Box width={440} ml="auto" mr="auto">
          <BuildingFields />
          <RemovalItemsFieldsVisible
            value={removalItemsFieldsVisible ? "1" : "0"}
            onChange={handleRemovalItemsFieldsVisibleChange}
          />
          {removalItemsFieldsVisible && <RemovalItemsFields />}
          {errorText && (
            <FieldWrapper>
              <ErrorAlert>{errorText}</ErrorAlert>
            </FieldWrapper>
          )}
          <Box textAlign="center">
            <SubmitButton disabled={!!loading} />
          </Box>
        </Box>
      </FormProvider>
    </form>
  );
};
