import { useEffect, useState } from "react";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type ConstructionRoadSizeField = Pick<
  SimulationFormValues,
  "construction_road_size"
>;

type Props = {
  value: "0" | "1" | null;
};

type HookReturnType = {
  field: ControllerRenderProps<
    ConstructionRoadSizeField,
    "construction_road_size"
  >;
  loading: boolean | undefined;
  hasFieldError: boolean;
  selectedConstructionRoadSize: "0" | "1" | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const useConstructionRoadSizeField = ({
  value,
}: Props): HookReturnType => {
  const { control } = useFormContext<ConstructionRoadSizeField>();
  const { field, fieldState, formState } = useController({
    name: "construction_road_size",
    control,
  });

  const [selectedConstructionRoadSize, setSelectedConstructionRoadSize] =
    useState<"0" | "1" | null>(null);

  const loading = useLoadingState();

  useEffect(() => {
    setSelectedConstructionRoadSize(value ?? null);
  }, [value]);

  const hasFieldError =
    // フィールドを操作した後に、フィールドが未入力だった場合
    (fieldState.isTouched && selectedConstructionRoadSize === null) ||
    // サブミットボタンが押下された後に、フィールドが未入力だった場合
    (formState.isSubmitted && selectedConstructionRoadSize === null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value as "0" | "1" | null;
    field.onChange(value);
    setSelectedConstructionRoadSize(value);
  };

  return {
    field,
    loading,
    hasFieldError,
    selectedConstructionRoadSize,
    handleChange,
  };
};
