import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { GARBAGES } from "../../../../../../../common/constants/garbase";
import { useGarbageField } from "./hooks/useGarbageField";

export const GarbageField = (): JSX.Element => {
  const { field, loading, garbageValue, handleChange } = useGarbageField();

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl fullWidth>
      <InputLabel>不用品</InputLabel>
      <Select
        {...field}
        value={garbageValue}
        onChange={handleChange}
        label="不用品"
      >
        {GARBAGES.map((type, index) => (
          <MenuItem key={index} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
