import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { usePrefectureField } from "./hooks/usePrefectureField";

type Props = {
  value: string | null;
};

export const PrefectureField = ({ value }: Props): JSX.Element => {
  const {
    field,
    loading,
    hasFieldError,
    options,
    selectedPrefecture,
    handleChange,
  } = usePrefectureField({ value });

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <Autocomplete
      {...field}
      value={selectedPrefecture}
      onChange={handleChange}
      options={options}
      isOptionEqualToValue={(
        option: {
          label: string;
          value: string;
        },
        value: {
          label: string;
          value: string;
        }
      ) => {
        return option.value === value.value;
      }}
      getOptionLabel={(option: { label: string; value: string }) =>
        option.label
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="解体予定地の都道府県"
          // MEMO: エラー時にラベルまで赤色になるのを防ぐ
          InputLabelProps={{ error: false }}
          variant="outlined"
          size="small"
          error={hasFieldError}
        />
      )}
    />
  );
};
