export type BuildingStoryLabel =
  | "1階建て"
  | "2階建て"
  | "3階建て"
  | "4階建て以上"
  | "未選択";
export type BuildingStoryValue = "0" | "1" | "2" | "3" | "";

export const BUILDING_STORIES: {
  label: BuildingStoryLabel;
  value: BuildingStoryValue;
}[] = [
  { label: "未選択", value: "" },
  { label: "1階建て", value: "0" },
  { label: "2階建て", value: "1" },
  { label: "3階建て", value: "2" },
  { label: "4階建て以上", value: "3" },
];
