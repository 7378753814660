import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import { useDistanceToAdjacentBuildingField } from "./hooks/useDistanceToAdjacentBuildingField";

type Props = {
  value: "0" | "1" | null;
};

export const DistanceToAdjacentBuildingField = ({
  value,
}: Props): JSX.Element => {
  const {
    field,
    loading,
    hasFieldError,
    selectedDistanceToAdjacentBuilding,
    handleChange,
  } = useDistanceToAdjacentBuildingField({ value });

  if (loading) return <Skeleton variant="text" width={240} height={56} />;

  return (
    <FormControl error={hasFieldError}>
      <FormLabel>隣家との外壁間の距離</FormLabel>
      <RadioGroup
        {...field}
        row
        value={selectedDistanceToAdjacentBuilding}
        onChange={handleChange}
      >
        <FormControlLabel value="0" control={<Radio />} label="60cm未満" />
        <FormControlLabel value="1" control={<Radio />} label="60cm以上" />
      </RadioGroup>
    </FormControl>
  );
};
