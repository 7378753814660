import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { StoneValue } from "../../../../../../../../common/constants/stone";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type StoneField = Pick<SimulationFormValues, "stone">;

type HookReturnType = {
  field: ControllerRenderProps<StoneField, "stone">;
  loading: boolean | undefined;
  stoneValue: StoneValue;
  handleChange: (event: SelectChangeEvent<StoneValue>) => void;
};

export const useStoneField = (): HookReturnType => {
  const { control, watch } = useFormContext<StoneField>();
  const stoneField = watch("stone");
  const { field } = useController({
    name: "stone",
    control,
  });

  const [stoneValue, setStoneValue] = useState<StoneValue>("");

  const loading = useLoadingState();

  useEffect(() => {
    setStoneValue(stoneField ?? "");
  }, [stoneField]);

  const handleChange = (event: SelectChangeEvent<StoneValue>): void => {
    const value = event.target.value as StoneValue;
    field.onChange(value);
    setStoneValue(value);
  };

  return {
    field,
    loading,
    stoneValue,
    handleChange,
  };
};
