import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { useBuildingStoryField } from "./hooks/useBuildingStoryField";
import {
  BuildingStoryValue,
  BUILDING_STORIES,
} from "../../../../../../../common/constants/building-story";

type Props = {
  value: BuildingStoryValue;
};

export const BuildingStoryField = ({ value }: Props): JSX.Element => {
  const { field, loading, hasFieldError, selectedBuildingStory, handleChange } =
    useBuildingStoryField({ value });

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl fullWidth>
      <InputLabel>建物の階数</InputLabel>
      <Select
        {...field}
        value={selectedBuildingStory}
        onChange={handleChange}
        label="建物の階数"
        error={hasFieldError}
      >
        {BUILDING_STORIES.map((type, index) => (
          <MenuItem key={index} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
