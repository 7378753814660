import { SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { GarbageValue } from "../../../../../../../../common/constants/garbase";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type GarbageVField = Pick<SimulationFormValues, "garbage">;

type HookReturnType = {
  field: ControllerRenderProps<GarbageVField, "garbage">;
  loading: boolean | undefined;
  garbageValue: GarbageValue;
  handleChange: (event: SelectChangeEvent<GarbageValue>) => void;
};

export const useGarbageField = (): HookReturnType => {
  const { control, watch } = useFormContext<GarbageVField>();
  const garbageField = watch("garbage");
  const { field } = useController({
    name: "garbage",
    control,
  });

  const [garbageValue, setGarbageValue] = useState<GarbageValue>("");

  const loading = useLoadingState();

  useEffect(() => {
    setGarbageValue(garbageField ?? "");
  }, [garbageField]);

  const handleChange = (event: SelectChangeEvent<GarbageValue>): void => {
    const value = event.target.value as GarbageValue;
    field.onChange(value);
    setGarbageValue(value);
  };

  return {
    field,
    loading,
    garbageValue,
    handleChange,
  };
};
