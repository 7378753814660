import { useEffect, useState } from "react";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { useLoadingState } from "../../../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../../../SimulationFormValues";

type BlockWallField = Pick<
  SimulationFormValues,
  "block_wall_height" | "block_wall_width"
>;

type HookReturnType = {
  field: ControllerRenderProps<BlockWallField, "block_wall_width">;
  loading: boolean | undefined;
  hasError: boolean;
  widthValue: string | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const useWidthField = (): HookReturnType => {
  const { control, watch } = useFormContext<BlockWallField>();
  const heightField = watch("block_wall_height");
  const widthField = watch("block_wall_width");
  const { field } = useController({
    name: "block_wall_width",
    control,
  });

  const [widthValue, setWidthValue] = useState<string>("");

  const loading = useLoadingState();

  useEffect(() => {
    setWidthValue(widthField ?? "");
  }, [widthField]);

  const hasError = !!(!widthField && heightField);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    field.onChange(value);
    setWidthValue(value);
  };

  return {
    field,
    loading,
    hasError,
    widthValue,
    handleChange,
  };
};
