import { config } from "../config";
import queryString from "query-string";

type ResponseValue = {
  predictedValue: number;
};

type ReturnType = {
  value: ResponseValue | null;
  error: null | { statusCode: number; message: string };
};

export const fetchPredictValue = async (
  queryString?: string
): Promise<ReturnType> => {
  const returnObject: ReturnType = {
    value: null,
    error: null,
  };
  const response = await fetch(
    `${config.apiBaseUrl}${queryString ? `?${queryString}` : ""}`,
    { mode: "cors" }
  );

  if (!response.ok) {
    const message = (await response.json()) as { errorMessage: string };
    returnObject.error = {
      statusCode: response.status,
      message: message.errorMessage,
    };
    return returnObject;
  }

  const responseValue = (await response.json()) as {
    predictedValue: number;
  };
  returnObject.value = responseValue;

  return returnObject;
};

/**
 * @description クエリ文字列の組み立てを行う
 */
export const buildQueryString = (
  params: Record<string, string | null>
): string => {
  const queryParams = {
    ...params,
    // 建物種別がその他の場合は一戸建てとしてまとめる
    building_type: params.building_type === "99" ? "0" : params.building_type,
  };

  // 入力項目が空な場合はクエリパラメータに含めない
  return queryString.stringify(queryParams, {
    skipEmptyString: true,
    skipNull: true,
  });
};
