import { Box } from "@mui/material";
import { SimulationForm, SimulationResults } from "./components";
import { SimulationProvider } from "./providers";

export const Simulation = (): JSX.Element => {
  return (
    <Box my={2}>
      <SimulationProvider>
        <Box width={600} height={45} ml="auto" mr="auto">
          <Box textAlign="center" mb={2}>
            <SimulationResults />
          </Box>
          <Box>
            <SimulationForm />
          </Box>
        </Box>
      </SimulationProvider>
    </Box>
  );
};
