import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { simulationParamMapping } from "../../../../../utils/simulationParamMapping";

export const useSimulationParams = () => {
  const search = useLocation().search;
  const queryParams = queryString.parse(search);
  const params = simulationParamMapping(queryParams);

  return params;
};
