import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { useBuildingTypeField } from "./hooks/useBuildingTypeField";
import {
  BuildingTypeValue,
  BUILDING_TYPES,
} from "../../../../../../../common/constants/building-type";

type Props = {
  value: BuildingTypeValue;
};

export const BuildingTypeField = ({ value }: Props): JSX.Element => {
  const { field, loading, hasFieldError, selectedBuildingType, handleChange } =
    useBuildingTypeField({
      value,
    });

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl fullWidth>
      <InputLabel>建物の種類</InputLabel>
      <Select
        {...field}
        value={selectedBuildingType}
        onChange={handleChange}
        label="建物の種類"
        error={hasFieldError}
      >
        {BUILDING_TYPES.map((type, index) => (
          <MenuItem key={index} value={type.value}>
            {type.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
