import {
  SimulationFormValues,
  buildDefaultValues,
  RemovalItemCosts,
} from "../SimulationFormValues";
import { useSimulationParams } from "./useSimulationParams";
import { useSimulatedCostDispatch } from "../../../providers/SimulationProvider/hooks/useSimulatedCostDispatch";
import { useLoadingDispatch } from "../../../providers/SimulationProvider/hooks/useLoadingDispatch";
import { useLoadingState } from "../../../providers/SimulationProvider/hooks/useLoadingState";
import { useForm, UseFormReturn } from "react-hook-form";
import { useEffect, useState } from "react";
import { useRemovalItemCostsDispatch } from "../../../providers/SimulationProvider/hooks/useRemovalItemCostsDispatch";
import { buildRemovalItemCosts } from "../SimulationFormValues";
import {
  buildQueryString,
  fetchPredictValue,
} from "../../../../../api/predict";

type HookReturnType = {
  formMethods: UseFormReturn<SimulationFormValues>;
  removalItemsFieldsVisible: boolean;
  loading: boolean | undefined;
  handleRemovalItemsFieldsVisibleChange: (value: string) => void;
  errorText: string | null;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

export const useSimulationForm = (): HookReturnType => {
  const defaultValues = buildDefaultValues();
  const params = useSimulationParams();

  const formMethods = useForm<SimulationFormValues>({
    defaultValues: {
      ...defaultValues,
      ...{
        ...params,
        // MEMO: 以下のフィールドのコンポーネントはNull許容していないので空文字で対応
        building_type: params.building_type ?? "",
        building_stories: params.building_stories ?? "",
        building_structure: params.building_structure ?? "",
      },
    },
  });

  const [removalItemsFieldsVisible, setRemovalItemsFieldsVisible] =
    useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);

  useEffect(() => {
    const formValues = formMethods.getValues();
    // MEMO: 初回マウント時でfiledに初期値に値が入っていたら全てのfiledをtouchedと見なす
    if (Object.values(formValues).some((v) => !!v)) {
      // touchをセットしているだけなのでもっといい書き方ありそう（現状だとsetTouchedなるものがない）
      const formKeys = Object.keys(formValues) as (keyof typeof formValues)[];
      formKeys.forEach((key) =>
        formMethods.setValue(key, formValues[key], { shouldTouch: true })
      );
    }
  }, []);

  const loading = useLoadingState();
  const loadingDispatcher = useLoadingDispatch();
  const simulatedCostDispatcher = useSimulatedCostDispatch();
  const removalItemCostsDispatcher = useRemovalItemCostsDispatch();

  const handleRemovalItemsFieldsVisibleChange = (value: string) => {
    value === "1"
      ? setRemovalItemsFieldsVisible(true)
      : setRemovalItemsFieldsVisible(false);
  };

  const handleSubmit = formMethods.handleSubmit(async (formValues) => {
    loadingDispatcher(true);

    const isFormValid = formValidation(formValues);
    // APIを叩く際に必須な項目が入力されていない場合は相場を取得しない
    if (!isFormValid) {
      loadingDispatcher(false);
      return;
    }

    if (removalItemsFieldsVisible) {
      const calculatedRemovalItemCosts = calculateRemovalItems(formValues);
      removalItemCostsDispatcher(calculatedRemovalItemCosts);
    } else {
      removalItemCostsDispatcher(buildRemovalItemCosts());
    }

    const queryString = buildQueryString(formValues);
    const response = await fetchPredictValue(queryString);

    if (response.error) {
      loadingDispatcher(false);
      setErrorText(buildErrorText(response.error));
      return new Error("相場データが取得できませんでした");
    }

    if (response.value) simulatedCostDispatcher(response.value.predictedValue);

    setErrorText(null);
    loadingDispatcher(false);
  });

  return {
    formMethods,
    loading,
    removalItemsFieldsVisible,
    handleRemovalItemsFieldsVisibleChange,
    errorText,
    handleSubmit,
  };
};

/**
 *
 * @description APIを叩く際に結果取得に必須な項目が入力されているかいないか
 * @todo 今後はシミュレーターを使う人がわかりやすい様にフォームにも視覚的にバリデーションの内容を表示させる
 */
const formValidation = (formValues: SimulationFormValues): boolean => {
  const {
    web_estate_entry_id,
    user_id,
    block_wall_height,
    block_wall_width,
    tree,
    carport,
    stone,
    garbage,
    ...rest
  } = formValues;
  const isFormValid = Object.values(rest).every((v) => v);

  return isFormValid;
};

/**
 *
 * @description 撤去物の費用の計算
 * @returns 建物以外の撤去費用
 */
const calculateRemovalItems = (
  formValues: SimulationFormValues
): RemovalItemCosts => {
  // ㎡での単価
  const blockWallUnitCost = 3000;
  // 軽トラック（積載）の台数での単価
  const carportUnitCost = 25000;
  // 軽トラック（積載）の台数での単価
  const treeUnitCost = 8000;
  // 軽トラック（積載）の台数での単価
  const stoneUnitCost = 40000;
  // 軽トラック（積載）の台数での単価
  const garbageUnitCost = 30000;

  // ブロック塀の高さ（段積）は1段を19cmとする
  const blockWallStackUnit = 19;

  const { block_wall_height, block_wall_width, carport, tree, stone, garbage } =
    formValues;

  const calculatedRemovalItemCosts: RemovalItemCosts = {
    blockWall: 0,
    carport: 0,
    tree: 0,
    stone: 0,
    garbage: 0,
  };

  // ブロック塀は高さと幅の両方入力されてないと計算しない
  if (
    (block_wall_height !== null || block_wall_height !== "") &&
    (block_wall_width !== null || block_wall_width !== "")
  )
    calculatedRemovalItemCosts.blockWall =
      ((Number(block_wall_height) * blockWallStackUnit) / 100) *
      Number(block_wall_width) *
      blockWallUnitCost;

  if (carport !== "")
    calculatedRemovalItemCosts.carport = Number(carport) * carportUnitCost;

  if (tree !== "")
    calculatedRemovalItemCosts.tree = Number(tree) * treeUnitCost;

  if (stone !== "")
    calculatedRemovalItemCosts.stone = Number(stone) * stoneUnitCost;

  if (garbage !== "")
    calculatedRemovalItemCosts.garbage = Number(garbage) * garbageUnitCost;

  return calculatedRemovalItemCosts;
};

/**
 * @param error
 * @description レスポンスされたステータスコードによってエラーテキストを返す
 * @returns エラーテキスト
 */
const buildErrorText = (error: { statusCode: number; message: string }) => {
  // TODO: 今後は他のエラーコードも対応する
  switch (error.statusCode) {
    case 404:
      return "条件に合致する相場情報が見つかりませんでした。条件を変更の上で再度お試しください。";
    case 500:
      return "サーバーでエラーが発生しました。";
    default:
      return "エラーが発生しました。";
  }
};
