import { ReactNode } from "react";
import { Alert } from "@mui/material";

type Props = {
  children?: ReactNode;
};

const defaultMessage = "エラーが発生しました。";

// エラーレポートはこのコンポーネントで行えるといいかも
export const ErrorAlert = ({
  children = defaultMessage,
}: Props): JSX.Element => {
  return <Alert severity="error">{children}</Alert>;
};
