import { BuildingTypeValue } from "../../../../common/constants/building-type";
import { BuildingStoryValue } from "../../../../common/constants/building-story";
import { BuildingStructureValue } from "../../../../common/constants/building-structure";
import { TreeValue } from "../../../../common/constants/tree";
import { CarportValue } from "../../../../common/constants/carport";
import { StoneValue } from "../../../../common/constants/stone";
import { GarbageValue } from "../../../../common/constants/garbase";

export type SimulationFormValues = {
  // 相場取得に必要な項目
  building_type: BuildingTypeValue;
  building_stories: BuildingStoryValue;
  building_structure: BuildingStructureValue;
  floor_area: string | null;
  construction_road_size: "1" | "0" | null;
  distance_to_adjacent_building: "1" | "0" | null;
  prefecture_id: string | null;
  // ログ追跡用の項目
  web_estate_entry_id: string | null;
  user_id: string | null;
  // 建物以外の撤去物の項目
  block_wall_height: string | null;
  block_wall_width: string | null;
  carport: CarportValue;
  tree: TreeValue;
  stone: StoneValue;
  garbage: GarbageValue;
};

export type RemovalItemCosts = {
  blockWall: number;
  carport: number;
  tree: number;
  stone: number;
  garbage: number;
};

export const buildRemovalItemCosts = (): RemovalItemCosts => ({
  blockWall: 0,
  carport: 0,
  tree: 0,
  stone: 0,
  garbage: 0,
});

export const buildDefaultValues = (): SimulationFormValues => ({
  building_type: "",
  building_stories: "",
  building_structure: "",
  floor_area: null,
  construction_road_size: null,
  distance_to_adjacent_building: null,
  prefecture_id: null,
  web_estate_entry_id: null,
  user_id: null,
  block_wall_height: null,
  block_wall_width: null,
  carport: "",
  tree: "",
  stone: "",
  garbage: "",
});
