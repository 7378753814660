import React from "react";
import { BlockWallField } from "./BlockWallField";
import { GarbageField } from "./GarbageField";
import { StoneField } from "./StoneField";
import { TreeField } from "./TreeField";
import { CarportField } from "./CarportField";
import { FieldWrapper } from "../FieldWrapper";

export const RemovalItemsFields = (): JSX.Element => {
  return (
    <React.Fragment>
      <FieldWrapper>
        <BlockWallField />
      </FieldWrapper>
      <FieldWrapper>
        <CarportField />
      </FieldWrapper>
      <FieldWrapper>
        <TreeField />
      </FieldWrapper>
      <FieldWrapper>
        <StoneField />
      </FieldWrapper>
      <FieldWrapper>
        <GarbageField />
      </FieldWrapper>
    </React.Fragment>
  );
};
