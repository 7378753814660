type AppConfig = {
  apiBaseUrl: string | undefined;
};

export const config: AppConfig = {
  apiBaseUrl:
    process.env.REACT_APP_VERCEL_ENV === "production"
      ? process.env.REACT_APP_PRD_API_BASE_URL
      : process.env.REACT_APP_API_BASE_URL,
};
