export type StoneLabel =
  | "未選択"
  | "軽トラック1台分"
  | "軽トラック2台分"
  | "軽トラック3台分"
  | "軽トラック4台分"
  | "軽トラック5台分"
  | "軽トラック6台分"
  | "軽トラック7台分"
  | "軽トラック8台分"
  | "軽トラック9台分"
  | "軽トラック10台分";
export type StoneValue =
  | ""
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "9"
  | "10";

export const STONES: {
  label: StoneLabel;
  value: StoneValue;
}[] = [
  {
    label: "未選択",
    value: "",
  },
  {
    label: "軽トラック1台分",
    value: "1",
  },
  {
    label: "軽トラック2台分",
    value: "2",
  },
  {
    label: "軽トラック3台分",
    value: "3",
  },
  {
    label: "軽トラック4台分",
    value: "4",
  },
  {
    label: "軽トラック5台分",
    value: "5",
  },
  {
    label: "軽トラック6台分",
    value: "6",
  },
  {
    label: "軽トラック7台分",
    value: "7",
  },
  {
    label: "軽トラック8台分",
    value: "8",
  },
  {
    label: "軽トラック9台分",
    value: "9",
  },
  {
    label: "軽トラック10台分",
    value: "10",
  },
];
