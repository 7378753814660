import { useEffect, useState } from "react";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type FloorAreaField = Pick<SimulationFormValues, "floor_area">;

type Props = {
  value: string | null;
};

type HookReturnType = {
  field: ControllerRenderProps<FloorAreaField, "floor_area">;
  loading: boolean | undefined;
  hasFieldError: boolean;
  floorAreaValue: string | null;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const useFloorAreaFieldField = ({ value }: Props): HookReturnType => {
  const { control } = useFormContext<FloorAreaField>();
  const { field, fieldState, formState } = useController({
    name: "floor_area",
    control,
  });

  const [floorAreaValue, setFloorAreaValue] = useState<string>("");

  const loading = useLoadingState();

  useEffect(() => {
    setFloorAreaValue(value ?? "");
  }, [value]);

  const hasFieldError =
    // フィールドを操作した後に、フィールドが未入力だった場合
    (fieldState.isTouched && floorAreaValue === "") ||
    // サブミットボタンが押下された後に、フィールドが未入力だった場合
    (formState.isSubmitted && floorAreaValue === "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    field.onChange(value);
    setFloorAreaValue(value);
  };

  return {
    field,
    loading,
    hasFieldError,
    floorAreaValue,
    handleChange,
  };
};
