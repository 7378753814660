import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import { useLoadingState } from "../../../../providers/SimulationProvider/hooks/useLoadingState";

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const RemovalItemsFieldsVisible = ({ value, onChange }: Props) => {
  const loading = useLoadingState();
  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl>
      <FormLabel>建物以外の撤去物はありますか？</FormLabel>
      <RadioGroup
        row
        value={value}
        onChange={(_e, value: string) => onChange(value)}
      >
        <FormControlLabel value="1" control={<Radio />} label="はい" />
        <FormControlLabel value="0" control={<Radio />} label="いいえ" />
      </RadioGroup>
    </FormControl>
  );
};
