import {
  FormControl,
  FormLabel,
  InputAdornment,
  Skeleton,
  TextField,
} from "@mui/material";
import { useWidthField } from "./hooks/useWidthField";

export const WidthField = (): JSX.Element => {
  const { field, loading, hasError, widthValue, handleChange } =
    useWidthField();

  if (loading) return <Skeleton variant="text" width={440} height={56} />;

  return (
    <FormControl>
      <FormLabel>ブロック塀</FormLabel>
      <TextField
        {...field}
        type="number"
        value={widthValue}
        onChange={handleChange}
        error={hasError}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          style: { textAlign: "end" },
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">横</InputAdornment>,
          endAdornment: <InputAdornment position="end">m</InputAdornment>,
        }}
        sx={[{ textAlign: "end" }]}
      />
    </FormControl>
  );
};
