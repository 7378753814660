import { Box } from "@mui/material";
import { HeightField } from "./components";
import { WidthField } from "./components";

export const BlockWallField = (): JSX.Element => {
  return (
    <Box display="flex">
      <HeightField />
      <WidthField />
    </Box>
  );
};
