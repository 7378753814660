import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { PREFECTURES } from "../../../../../../../../common/constants";
import { useLoadingState } from "../../../../../../providers/SimulationProvider/hooks/useLoadingState";
import {
  useFormContext,
  useController,
  ControllerRenderProps,
} from "react-hook-form";
import { SimulationFormValues } from "../../../../SimulationFormValues";

type PrefectureField = Pick<SimulationFormValues, "prefecture_id">;

type Props = {
  value: string | null;
};

type HookReturnType = {
  field: ControllerRenderProps<PrefectureField, "prefecture_id">;
  loading: boolean | undefined;
  hasFieldError: boolean;
  options: {
    label: string;
    value: string;
  }[];
  selectedPrefecture: {
    label: string;
    value: string;
  } | null;
  handleChange: (
    _event: ChangeEvent<unknown>,
    value: {
      label: string;
      value: string;
    } | null
  ) => void;
};

export const usePrefectureField = ({ value }: Props): HookReturnType => {
  const options = useMemo(
    () =>
      PREFECTURES.map((prefecture) => ({
        label: prefecture.label as string,
        value: prefecture.value as string,
      })),
    []
  );

  const { control } = useFormContext<PrefectureField>();
  const { field, fieldState, formState } = useController({
    name: "prefecture_id",
    control,
  });

  const [selectedPrefecture, setSelectedPrefecture] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const loading = useLoadingState();

  useEffect(() => {
    setSelectedPrefecture(
      (PREFECTURES.find((prefecture) => prefecture.value === value) as {
        label: string;
        value: string;
      }) ?? null
    );
  }, [value]);

  const hasFieldError =
    // フィールドを操作した後に、フィールドが未入力だった場合
    (fieldState.isTouched && selectedPrefecture === null) ||
    // サブミットボタンが押下された後に、フィールドが未入力だった場合
    (formState.isSubmitted && selectedPrefecture === null);

  const handleChange = (
    _event: ChangeEvent<unknown>,
    value: {
      label: string;
      value: string;
    } | null
  ): void => {
    setSelectedPrefecture(value);
    field.onChange(value?.value ?? null);
  };

  return {
    field,
    loading,
    hasFieldError,
    options,
    selectedPrefecture,
    handleChange,
  };
};
